<template>
    <div>
        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-data-table
            :headers="headers"
            :items="lista"
            :items-per-page="itemsPerPage"
            hide-default-footer
            class="elevation-1"
            :loading="loadingTable"
            :key="`list-documents-electronics-${tenantId}`"
            :options.sync="options"
        >
            <template v-slot:top>
                <ExpandableFilters 
                    title=""
                    titleButton="Cargar archivos"
                    classButton='py-0 py-md-2 text-center mb-md-0 col-6 col-sm-2 px-md-0'
                    iconButtom="mdi-upload"
                    classTitle="col-0"
                    :filters=filters
                    @datafilters=cargarLista(true)
                    :actionButton="()=> {dialogUploadFile = true}"
                />
            </template>
            <!-- <template v-slot:item.opcion="{item}">
                <v-btn 
                    icon 
                    color='red' 
                    target="_blank" 
                    @click="formInvoice(item.clave_acceso)"
                >
                    <v-icon color='#ff9800' >mdi-table-edit</v-icon>
                </v-btn>
            </template>  -->
            <template v-slot:item.opcion="{item}">
                <v-btn 
                    v-if="item.xml!='' && item.xml!=null"
                    icon 
                    color='red' 
                    target="_blank" 
                    @click="downloadPdf(item.clave_acceso)"
                >
                    <v-icon color='red'>mdi-file-pdf-box</v-icon>
                </v-btn>
                <v-btn 
                    v-if="item.tipo_documento == 'Nota de crédito' && item.estado=='RECIBIDO'"
                    icon 
                    color='red' 
                    target="_blank" 
                    @click="formStatus(item.clave_acceso)"
                >
                    <v-icon color='primary'>mdi-reload</v-icon>
                </v-btn>
            </template>
            <template v-slot:footer>
                <v-pagination
                    class='mt-10'
                    v-model="currentPage"
                    :length="pageCount"
                    @input="handlePageChange"                    
                    total-visible=10     
                ></v-pagination>
            </template>
        </v-data-table>
        <v-dialog
            v-model="dialog"
            fullscreen
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                    dark
                    small
                    color="primary"
                >
                    <v-btn
                        icon
                        dark
                        @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Factura#  {{invoicesNumber}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn
                            dark
                            text
                            @click="dialog = false"
                        >
                        Salir
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card class="pl-6 pr-6 pt-10">
                    <v-row>
                        <v-col sm=6 md="4" class="py-0" >
                            <v-text-field
                                label="Proveedor*"
                                dense
                                readonly
                                v-model="documentReceived.razon_social"
                            ></v-text-field>
                        </v-col>
                        <v-col sm=6 md="2" class="py-0">
                            <v-text-field
                                label="Estado*"
                                dense
                                readonly
                                v-model="documentReceived.estado"
                            ></v-text-field>
                        </v-col>
                        <v-col sm=6 md="2" class="py-0">
                            <v-text-field
                            label="Fecha de emisión*"
                            type="text"
                            dense
                            readonly
                            v-model="documentReceived.fecha"
                            ></v-text-field>
                        </v-col>
                        <v-col sm="6" md="2" class="py-0">
                            <v-text-field
                                label="Fecha de vencimiento "
                                type="text"
                                dense
                                readonly
                            ></v-text-field>
                        </v-col>
                        <v-col md="2" sm="6" class="pt-0 pb-0">
                            <v-text-field
                                label="Numero*"
                                readonly
                                dense
                                :value=invoicesNumber
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col md="12" sm="12">
                            <v-data-table
                                :headers="headItems"
                                :items="itemsComprobante"
                                :items-per-page="200"
                                hide-default-footer
                                class="elevation-1"
                            ></v-data-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-card>
        </v-dialog>
        <v-dialog 
            width="500px"
            v-model=dialogUploadFile>
            <v-card>
                <v-card-title>
                    Archivo de facturas recibidas
                </v-card-title>
                <v-col >
                    <v-radio-group
                        v-model="typeDocument"
                        row
                        class="text-center"
                    >
                        <v-radio value="txt">
                            <template v-slot:label>
                                <div style="font-size: 17px;">TXT</div>
                            </template>
                        </v-radio>
                        <v-radio value="xml">
                            <template v-slot:label>
                                <div style="font-size: 17px;">XML</div>
                            </template>
                        </v-radio>
                    </v-radio-group>                    
                </v-col>
                <v-col cols=12 class="py-0">
                    <v-form 
                        ref="form_upload_txt"
                        v-if="typeDocument == 'txt'"
                    >
                        <v-file-input
                            label="Cargar archivo txt"
                            small
                            outlined
                            dense
                            v-model="fileTxt"
                            @change=setFile($event)
                            clearable
                            accept=".txt"
                            :rules=required
                        ></v-file-input>
                    </v-form>
                    <v-form 
                        ref="form_upload_xml"
                        v-if="typeDocument == 'xml'"
                    >
                        <v-file-input
                            label="Cargar archivos xml"
                            small
                            outlined
                            dense
                            v-model="filesXml"
                            @change=setFileXml($event)
                            clearable
                            accept=".xml"
                            multiple                           
                            :rules=requiredFileXml
                        />
                    </v-form>
                </v-col>
                <v-card-actions >
                    <v-col class='text-center'>
                        <v-btn
                            v-if="typeDocument == 'txt'"
                            color="primary"
                            @click="uploadFileTxt"
                            :loading=loadingBtn
                        >
                            <v-icon>mdi-content-save</v-icon> Cargar archivo
                        </v-btn>
                        <v-btn
                            v-if="typeDocument == 'xml'"
                            color="primary"
                            @click="uploadFileXml"
                            :loading=loadingBtn
                        >
                            <v-icon>mdi-content-save</v-icon> Cargar archivo
                        </v-btn>
                        
                        <v-btn
                            @click="dialogUploadFile = !dialogUploadFile"
                        >
                            <v-icon>mdi-cancel</v-icon> Cancelar
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog 
            width="500px"
            v-model=dialogStatus>
            <v-card>
                <v-card-title>
                    Cambiar estado del documento
                </v-card-title>
                <v-col cols=12 class="py-0">
                    <v-select
                        class=" px-3"
                        v-model=statusDocument
                        :items=status2
                        item-value=estadoId
                        item-text=estadoNombre
                    />
                </v-col>
                <v-card-actions >
                    <v-col class='text-center'>
                        <v-btn
                            color="primary"
                            @click="changeStatus"
                            :loading=loadingBtn
                        >
                            <v-icon>mdi-content-save</v-icon> Cambiar estado
                        </v-btn>
                        <v-btn
                            @click="dialogStatus = !dialogStatus"
                        >
                            <v-icon>mdi-cancel</v-icon> Cancelar
                        </v-btn>
                    </v-col>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex'
import Vue from 'vue'
import axios from 'axios'
import ExpandableFilters from '../general/ExpandableFilters'

export default {
	name: "ElectronicsDocumentsReceivedComponent",
    components:{
        ExpandableFilters
    },
	data: ()=> ({
    	headers: [
    		{ text: 'Opciones', value: 'opcion' },
            { text: 'Numero', value: 'secuencial' },
            { text: 'clave acceso', value: 'clave_acceso' },
            { text: 'Comprobante afectado', value: 'comprobante_afectado' },
            { text: 'Fecha', value: 'fecha' },
            { text: 'Tipo', value: 'tipo_documento' },             
            { text: 'Estado', value: 'estado' },
            { text: 'Emisor', value: 'razon_social'},
            { text: 'Total', value: 'importe_total', align:"end"},
        ],
        headItems: [
            { text: 'Código', value: 'codigo_principal'},
            { text: 'Descripcion', value: 'descripcion'},               
            { text: 'Cantidad', value: 'cantidad'},
            { text: 'Precio untario', value: 'precio_unitario'},
            { text: 'Precio sin impuesto', value: 'precio_sin_impuesto'},
            { text: 'Impuesto', value: 'impuesto'},
            { text: 'Descuento', value: 'descuento'},
            { text: 'Total', value: 'total'}
        ],
        filters:[
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model:'',
                label: 'Número',
                type:'input_text'
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pr-0 pr-sm-2',
                v_model:[],
                label: 'Fecha',
                type:'input_date',
                clearable:true,
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model:'',
                label: 'Tipo',
                type:'input_select',
                text:'tipoNombre',
                value:'tipoId',
                clearable:true,
                multiple:true,
                items:[
                    {'tipoId': '01','tipoNombre':'Factura'},
                    {'tipoId':'04','tipoNombre': 'Nota de crédito'},
                    {'tipoId':'07','tipoNombre':'Retención'},
                    {'tipoId':'05','tipoNombre':'Nota de débito'}
                ]
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model:'',
                label: 'Estado',
                type:'input_select',
                text:'estadoNombre',
                value:'estadoId',
                clearable:true,
                items:[
                    {'estadoId': 'RECIBIDO','estadoNombre':'Recibido'},
                    {'estadoId':'PROCESADO','estadoNombre': 'Procesado'},
                    {'estadoId': 'GASTO_PERSONAL','estadoNombre':'Gasto personal'},
                    {'estadoId': 'NO_PROCESAR','estadoNombre':'No procesar'}
                ],
            },
            {
                cols:6,
                class:'py-0 py-md-2 col-md-2 pl-0 pl-sm-2',
                v_model:'',
                label: 'Proveedor',
                type:'input_text'
            }
        ],
        status2:[
            {'estadoId': 'GASTO_PERSONAL','estadoNombre':'GASTO PERSONAL'},
            {'estadoId': 'NO_PROCESAR','estadoNombre':'NO PROCESAR'}
        ],
        cabecera: {
            invoice_date: "",
            cliente: "",
            estado: ""
        },
        documentReceived:{
            estado:null,
            fecha:null,
            importe_total:null,
            razon_social:null,
            procesado_por:null,
            secuencial:null,
            pto_emi:null,
            estab:null
        },
        lista :[],
        itemsFactura: [],
        totalesFactura: [],
        itemsComprobante:[],
        options: {},
        currentPage: 1,
        pageCount: 1,
        itemsPerPage: 20,
        searchProveedor:null,
        dialog: false,
        dialogStatus:false,
        dialogUploadFile:false,
        notifications: false,
        overlay:false,
        sound: true,
        widgets: true,
        searchSecuencial: null,
        searchFecha: null,
        searchEstado: null,
        searchCliente: null,
        searchTipoDoc: null,
        statusDocument:'GASTO_PERSONAL',
        typeDocument:'txt',
        fileTxt:null,
        filesXml:[],
        accessKey:null,
        required:[v => !!v || 'El campo es requerido'],
        requiredFileXml:[v => !!v.length && v.length <= 10 || 'Seleccione sólo 10 archivos a la vez']
    }),
    computed: {
    	...mapState('master',['loadingTable','loadingBtn','user','tenantId','token','url']),

        invoicesNumber(){
            return `${this.documentReceived.estab}-${this.documentReceived.pto_emi}-${this.documentReceived.secuencial}`
        }
    },
    watch:{
        options: {
            handler () {
                this.cargarLista()
            },
            deep: true,
        },
    },
    methods: {

    	...mapMutations('master',['setUrl','setOverlay','setMenu','setLoadingTable','setLoadingBtn','setTitleToolbar']),
	   	
	    ...mapActions('master',['requestApi','alertNotification']),
	     
        cargarLista(seCurrentPage) {
              
    		if(!!seCurrentPage) this.currentPage = 1
            this.lista=[]
            this.pageCount=1
            
            this.setLoadingTable(true)
            this.setUrl('comprobante-electronico')

            this.requestApi({
                method : 'GET',
                data : {
                	"tipo": this.filters[2].v_model,
			        "page": this.currentPage, 
			        "page_count": this.pageCount, 
			        "page_size": this.itemsPerPage,
			        "proveedor" : this.filters[4].v_model,
			        "secuencial":this.filters[0].v_model,
			        "estado" : this.filters[3].v_model,
			        "inicio": this.filters[1].v_model[0],
                    "fin": this.filters[1].v_model[1],
                    "pagination" : true
			    }
            }).then(res=>{
                
            	this.lista = res.data.comprobantes;
            	this.pageCount = res.data.page_count;      

            }).then(()=>{
                this.setLoadingTable(false)
            })

        },

        formInvoice(invid) {
          
            this.setUrl('comprobante-electronico/'+invid)
            this.requestApi({
                method : 'GET',
                data : {
			        
			    }
            }).then(res=>{
                console.log(res)
                const {
                    estado,
                    fecha,
                    importe_total,
                    razon_social,
                    procesado_por,
                    secuencial,
                    pto_emi,
                    estab,
                    details
                } = res.data.datacomprobanteElectronico
            				    
                this.itemsComprobante = details                               
                

                Object.assign(this.documentReceived,{
                    estado,
                    fecha,
                    importe_total,
                    razon_social,
                    procesado_por,
                    secuencial,
                    pto_emi,
                    estab
                })
            	//console.log(res.data.cabecera , res.data.itemsFactura);			    		
				/* this.cabecera = res.data.cabecera;
				this.itemsFactura = res.data.itemsFactura;
				this.totalesFactura  = res.data.totales; */
				this.dialog = true

            }).then(()=>{
	              
            })
            
        },

        setFile(event){
            this.fileTxt = typeof event != 'undefined' ? event : null
        },

        setFileXml(event){
            this.filesXml = typeof event != 'undefined' ? event : null
        },

        uploadFileTxt(){
          
            if(!this.$refs.form_upload_txt.validate())
                return false

            this.setLoadingBtn(true)
            this.setUrl('comprobante-electronico')
        
            let formData = new FormData()
            
            formData.append('fileTxt',this.fileTxt)
            formData.append('tenantId',this.tenantId)
            formData.append('user',this.user.user_ligin_id)
            formData.append('pass',this.user.pass)
            formData.append('type','txt')

            axios.post(
                this.url,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': 'Bearer '+ this.token
                    }
                }
            ).then(res => {

                console.log('res')
                console.log(res)
                this.setLoadingBtn(false)
                this.alertNotification({
                    param:{
                        html: res.data.msg,
                        timer: 130000
                    }
                })
                this.cargarLista()
                this.fileTxt=null
                this.dialogUploadFile=false

            }).catch(err=>{
			
                console.log(err)

                this.setLoadingBtn(false)

                params={
                    title :'Error!',
                    html: err.data.detail,
                    icon:'error',
                    confirmButtonColor:'#d60400',
                    toast:false,
                    grow:false,
                    position:'center',
                    confirmButtonText:'Cerrar'
                }

                Vue.swal({...params});

            })

        },

        uploadFileXml(){
            console.log('valid',this.$refs.form_upload_xml.validate())
           if(!this.$refs.form_upload_xml.validate())
               return false

            this.setLoadingBtn(true)
            this.setUrl('comprobante-electronico')
        
            let formData = new FormData()

            this.filesXml.forEach((file,i)=>{
                formData.append('fileXml['+i+']',file)
            })
           
           formData.append('tenantId',this.tenantId)
           formData.append('user',this.user.user_ligin_id)
           formData.append('pass',this.user.pass)
           formData.append('type','xml')


            axios.post(
               this.url,
               formData,
               {
                   headers: {
                       'Content-Type': 'multipart/form-data',
                       'Authorization': 'Bearer '+ this.token
                   }
               }
           ).then(res => {

               console.log('res',res)

               this.setLoadingBtn(false)
               
               this.alertNotification({
                   param:{
                       html: res.data.msg,
                       timer: 130000
                   }
               })

               this.cargarLista()
               this.filesXml=[]
               this.dialogUploadFile=false

           }).catch(err=>{
           
               console.log(err)

               this.setLoadingBtn(false)

               Vue.swal({
                   title :'Error!',
                   html: err.data.detail,
                   icon:'error',
                   confirmButtonColor:'#d60400',
                   toast:false,
                   grow:false,
                   position:'center',
                   confirmButtonText:'Cerrar'
               });

           })

       },

        downloadPdf(clave_acceso){

            this.overlay=true
            this.setUrl('comprobante-electronico')

            this.requestApi({
                method : 'PATCH',
                data : { 
                    clave_acceso: clave_acceso
                }
            }).then(res=>{
                
            	//let win = window.open()
                //let base64URL = `data:application/pdf;base64,${res.data.detail[2].trim().replace('"',"").replace(/(\r\n|\n|\r)/gm,"")}`
                //win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')

                var a = document.createElement("a"); 
                a.href = "data:application/pdf;base64," + res.data.detail[2].trim().replace('"',"").replace(/(\r\n|\n|\r)/gm,""); 
                a.download = 'factura'+clave_acceso.substring(24,39) + '.pdf';
                
                a.click();  

            }).catch(()=>{

            }).then(()=>{
                this.overlay=false
            })

        },

        formStatus(clave_acceso){
            this.accessKey = clave_acceso
            this.dialogStatus=true
        },

        changeStatus(){
            
            this.overlay=true
            this.setUrl(`comprobante-electronico/${this.accessKey}`)

            this.requestApi({
                method : 'PATCH',
                data : {
                    status : this.statusDocument
                }
            }).then(res=>{

                this.alertNotification({param:{html: res.data.msg}})     
                this.cargarLista()

            }).catch(()=>{

            }).then(()=>{
                this.overlay=false
            })


        },

        handlePageChange() {      

            this.cargarLista()
            
        },
        
    },
    mounted(){
        this.setTitleToolbar('COMPROBANTES ELECTRÓNICOS RECIBIDOS')
    }
    
  }

</script>